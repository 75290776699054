























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

export class ExpandState {
  fullyExpand = false;
  moreLevel = false;

  constructor(
    public expandLevel = 1,
    public minLevel = 1,
    public showChildrenSummary = true,
  ) {}
}

@Component
export default class ExpandControl extends Vue {
  @Prop() state!: ExpandState;
  @Prop() active!: boolean;

  collapse() {
    if (this.state.expandLevel > this.state.minLevel)
      this.state.expandLevel--;
    this.state.fullyExpand = false;
  }

  collapseAll() {
    this.state.expandLevel = this.state.minLevel;
    this.state.fullyExpand = false;
  }

  canCollapse() {
    return this.state.expandLevel > this.state.minLevel || this.state.fullyExpand;
  }

  expend() {
    if (!this.canExpand()) return
    this.state.moreLevel = false;
    this.state.expandLevel++;
  }

  expendAll() {
    this.state.moreLevel = false;
    this.state.fullyExpand = true;
  }

  canExpand() {
    return this.state.moreLevel;
  }

  // Avoid to use global event listener that will cause conflict with other components.
  // mounted() { window.addEventListener('keypress', this.onKeyPress); }
  // unmounted() { window.removeEventListener('keypress', this.onKeyPress); }
  onKeyPress(e: KeyboardEvent) {
    console.log(e);
    // if (!this.active) return
    switch(e.key) {
      case 's': this.state.showChildrenSummary = !this.state.showChildrenSummary; return;
      case ',': this.collapse(); return;
      case '.': this.expend(); return;
      case '<': this.collapseAll(); return;
      case '>': this.expendAll(); return;
    }
  }
}















import { Component, Prop, Vue } from 'vue-property-decorator';
import { TDNode } from 'treedoc';

interface Item {
  text: string;
  active?: boolean;
  href?: string;
  node?: TDNode;
}

@Component
export default class JsonPath extends Vue {
  @Prop() private treeNode!: TDNode;

  onclick(item: Item) {
    this.$emit('node-clicked', ['', ...item.node!.path]);
  }

  get items() {
    const paths = new Array<Item>();
    if (!this.treeNode)
      return paths;

    paths.unshift({ text: this.treeNode.key!, active: true });
    for (let pNode = this.treeNode.parent; pNode; pNode = pNode!.parent) {
      paths.unshift({ text: pNode!.key!, href: '', node: pNode });
    }
    return paths;
  }
}

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TDNode } from 'treedoc';
import TreeState from '../models/TreeState';
import TreeUtil from '../models/TreeUtil';
import _ from 'lodash';

// number and between 1980-01-01 and 2040-01-01, maybe a Date
const START_TIME = new Date('1980-01-01').getTime();
const END_TIME = new Date('2040-01-01').getTime();

function tryDate(val: number): Date | null{
  if (val > START_TIME && val < END_TIME) {
    return new Date(val);
  }
  return null;
}

@Component
export default class SimpleValue extends Vue {
  @Prop() tnode!: TDNode;
  @Prop({required: false, default: false}) isInTable!: boolean;
  @Prop({required: false, default: false}) textWrap!: boolean;

  get ref() {
    if (this.tnode.key !== TreeUtil.KEY_REF || typeof(this.tnode.value) !== 'string')
      return null;
    return this.tnode.value;
  }

  get date() {
    const val = Number(this.tnode.value);
    if (!_.isNumber(val)) return null;
    const date = tryDate(val) || tryDate(val * 1000) || tryDate(val / 1000_000);
    return date ? `\n${date.toISOString()}` : null;
  }

  get refAbsolute() {
    let result = this.ref;
    if (result && result.startsWith('../'))
      result = this.tnode.parent!.pathAsString + '/' + result;
    return result;
  }

  get url() {
    const val = this.tnode.value;
    if (typeof(val) === 'string' && (val.startsWith('http://') || val.startsWith('https://')))
      return val;
  }

  get valueStyle() { return typeof(this.tnode.value) === 'string' ? 'string' : 'number'; }

  get whiteSpaceStyle() { return this.textWrap ? 'pre-wrap' : 'pre'; }
}




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import JsonTreeTable from './components/JsonTreeTable.vue';
import JsonTable from './components/JsonTable.vue';
import sampleData from './sampleData';
import TDVOptions from './models/TDVOption';
import YAMLParserPlugin from './parsers/YAMLParserPlugin';
import XMLParserPlugin from './parsers/XMLParserPlugin';
import CSVParserPlugin from './parsers/CSVParserPlugin';
import UrlParam from './UrlParam';
import JSONParserPlugin, { JSONParserOption, JSONParserType } from './parsers/JSONParserPlugin';
import PrometheusParserPlugin from './parsers/PrometheusParserPlugin';

@Component({
  components: {
    JsonTreeTable,
    JsonTable,
  },
})
export default class Home extends Vue {
  param = new UrlParam();
  sampleData = sampleData.data;
  jsonData: any = sampleData.data[0].value;

  tdvOption: TDVOptions = new TDVOptions().setParsers([
      new JSONParserPlugin('Lombok.toString', JSONParserType.LOMBOK_TO_STRING),
      new JSONParserPlugin('Map.toString', JSONParserType.JAVA_MAP_TO_STRING),
      new XMLParserPlugin('XML compact', 'text/xml', true),
      new XMLParserPlugin(),
      new XMLParserPlugin('html', 'text/html'),
      new CSVParserPlugin(),
      new CSVParserPlugin('TSV', '\t'),
      new CSVParserPlugin('SSV', ' '),
      new PrometheusParserPlugin(),
      new YAMLParserPlugin(),
      ]);

  private get jsonTreeTable() {
    return this.$refs.jsonTreeTable as JsonTreeTable;
  }

  // tslint:disable-next-line:max-line-length
  // Example url: http://localhost:8081/?data={a:1,b:[{b1:2,b2:3},%20{b1:4,b2:5}],c:3}&initialPath=/b&tableConfig={Pagination:false,columns:[{field:b1}]}&title=tableTest&option={maxPane:table,parsers:[]}#/
  beforeMount() {
    if (this.param.data)
      this.jsonData = this.param.data;

    if (this.param.option) {
      Object.assign(this.tdvOption, this.param.option);
    }

    if (this.param.tableConfig) {
      Object.assign(this.tdvOption.defaultTableOpt!, this.param.tableConfig);
    }

    if (this.param.embeddedId != null) {
      window.parent.postMessage({ type: 'tdv-ready', id: this.param.embeddedId }, '*');
      if (window.opener)
        window.opener.postMessage({ type: 'tdv-ready', id: this.param.embeddedId }, '*');

      window.addEventListener('message', evt => {
        if (evt.data.type !== 'tdv-setData')
          return;
        this.jsonData = evt.data.data;
      }, false);
    }
  }

  mounted() {    
    if (this.param.dataUrl)
      this.jsonTreeTable.openUrl(this.param.dataUrl);
  }
}









































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { DataTableOptions, FieldQuery, JS_QUERY_DEFAULT, Query } from './Vue2DataTable';
import DataFilter from './DataFilter';
import thFilter from './th-Filter.vue';
import tdValue from './td-Value.vue';
import tdKey from './td-Key1.vue';
import JsonPath from './JsonPath.vue';
import TreeState, { TableNodeState } from '../models/TreeState';
import JSONParserPlugin from '../parsers/JSONParserPlugin';
import ExpandControl, { ExpandState } from './ExpandControl.vue';
import { identity, ListUtil, TD, TDNode, TDNodeType } from 'treedoc';
import { TableUtil } from '../models/TableUtil';
import { createVerify } from 'crypto';

const COL_VALUE = '@value';
const COL_NO = '#';
const COL_KEY = '@key';

@Component({
  components: {
    JsonPath,
    ExpandControl,
  },
})
export default class JsonTable extends Vue {
  tableOpt: DataTableOptions = {
    // fixHeaderAndSetBodyMaxHeight: 200,
    // tblStyle: 'table-layout: fixed', // must
    tblClass: 'table-bordered',
    pageSizeOptions: [5, 20, 50, 100, 200, 500],
    columns: [],
    data: [],
    filteredData: [],
    rawData: [],
    total: 0,
    // Have to initialize all the fields to make them able to be persisted in cache. (reactivity problem by proxy?)
    query: new Query(),
    // !!!Have to initialize xprops.filteredData to make it reactive
    xprops: { tstate: null, filteredData:[] },
  };
  defTableOpt!: any;
  // !! class based component, we have to initialized the data field, "undefined" won't be reactive. !!
  // https://github.com/vuejs/vue-class-component#undefined-will-not-be-reactive
  tstate: TreeState = new TreeState({});
  isColumnExpanded = false;
  isColumnExpandedBuild = false;  // Flag to avoid duplicated rebuild()
  expandState = new ExpandState(0, 0, false);
  copyBuffer = '';
  showAdvancedQuery = false;

  @Prop() tableData!: TreeState | TDNode | object | string;
  @Prop() options?: DataTableOptions;
  @Prop() isInMuliPane?: boolean;  // TODO: Move to TDVTableOption


  rebuildTable(val: TDNode | null, cachedState: TableNodeState | null = null) {
    // use defTableOpt to get rid of this.options for non-initial node
    if (!this.defTableOpt)  // backup for the first time, we have to intialize tableOpt attributes to make them reactive
      this.defTableOpt = this.tableOpt;

    this.defTableOpt.columns = [];
    this.defTableOpt.query.fieldQueries = {};
    this.tableOpt = { ...this.defTableOpt, ...(this.applyCustomOpts && this.options) };
    if (cachedState) {
      this.tableOpt.query = cachedState.query;
      this.tableOpt.columns = cachedState.columns;
      this.isColumnExpanded = cachedState.isColumnExpanded;
    }
    this.buildTableAndQuery(val);
    this.tstate.hasTreeInTable = false;
    // console.log('clear hasTreeInTable');
    this.tableOpt.xprops.tstate = this.tstate;
    this.tableOpt.xprops.expandState = this.expandState;
    this.isColumnExpandedBuild = this.isColumnExpanded;
  }

  buildTableAndQuery(val: TDNode | null) {
    this.buildTable(val);
    this.queryData();
  }

  buildTable(val: TDNode | null) {
    this.tableOpt.rawData = [];

    if (!val)
      return;
    
    let extFunc = null;
    if (this.query.extendedFields) {
      const exp = `
        with($) {   // With doesn't work with Proxy (not sure why)
          // console.log(JSON.stringify($));
          return {${this.query.extendedFields}}
        }
      `;
      try { 
        extFunc = new Function('$', exp);
      } catch(e) {
        console.error(`Error parsing extend fields: ${exp}`);
        console.error(e);
      }
    }

    const ia = val.type === TDNodeType.ARRAY;
    const keyCol = ia ? COL_NO : COL_KEY;
    this.addColumn(keyCol, 0);
    if (val.children) {
      for (const v of val.children) {
        const row = {
          [keyCol]: ia ? Number(v.key) : v.key,
          [COL_VALUE]: v,
        };
        this.tableOpt.rawData.push(row);
        if (this.isColumnExpanded && v && v.children) {
          for (const cv of v.children) {
            this.addColumn(cv.key!);
            row[cv.key!] = cv;
          }
        } else {
          this.addColumn(COL_VALUE, 1);
        }
        if (extFunc) {
          try {
            const ext = extFunc(v.toObject(true, true));
            for (const k in ext)
              // if(!k.startsWith('$$'))   // internal fields (e.g. $$tdNode)
                this.addExtObject(k, ext[k], row);
          } catch(e) {
            console.error(`Error evalute ext fields: ${this.query.extendedFields}`);
            console.error(e);
          }
        }
      }
    }
  }

  addExtObject(key: string, val: any, row: any) {
    if (key.endsWith('_') && val) {  // spread the children
      if (Array.isArray(val)) {
        for (let i = 0; i < val.length; i++) {
          this.addColumn(key + i);
          row[key+i] = val[i]?.$$tdNode || val[i];
        }
        return;
      } else if (typeof val === 'object') {
        // for (const cv of val.children) {
        //   const ck = key + cv.key
        //   this.addColumn(ck);
        //   row[ck] = cv;
        // }
        for (const k of Object.keys(val)) {
          if(k.startsWith('$$')) continue;
          this.addColumn(key + k);
          row[key+k] = val[k]?.$$tdNode || val[k];
        }
        return;
      }
    }
    this.addColumn(key);
    row[key] = val?.$$tdNode || val;
  }

  addColumn(field: string, idx = this.tableOpt.columns.length) {
    const isKeyCol = idx === 0;
    const cols = this.tableOpt.columns;
    let col = cols.find(c => c.field === field);
    if (!col) {
      col = {
        field,
        visible: isKeyCol || !(this.applyCustomOpts && this.options!.columns),
      };
      cols.splice(idx, 0, col);
    }
    if (col.processed)
      return;

    col.title = col.title || field;
    col.sortable = true;
    col.thComp = col.thComp || thFilter;
    col.tdComp = col.tdComp || (isKeyCol ? tdKey : tdValue);
    col.processed = true;
    // VUETIP: we have to use Vue.$set, otherwise, once it's assigned with array syntax. this field will no longer
    // be reactive
    this.$set(this.tableOpt.query.fieldQueries, field, new FieldQuery());

    col.thClass = 'tdv-th';
    col.tdClass = 'tdv-td';
    if (isKeyCol) {
      col.thClass += ' tdv-min tdv-td';
      col.tdClass = 'tdv-min tdv-td';
    }
  }

  defaultExpand(val: TDNode) {
    if (!val)
      return false;
    const cols = new Set<string>();
    let cellCnt = 0;
    if (!val.children || val.children.length === 0)
      return false;

    for (const v of val.children) {
      if (v && v.children) {
        for (const child of v.children) {
          cols.add(child.key!);
          cellCnt++;
        }
      }
    }
    // k: threshold (blank cell / Total possible blank cells)
    // k = (r * c - cellCnt) / (r * c - c) => cellCnt = r * c - k * r * c + k * c = c (r - r * k + k) = c (r - (r-1)k)
    //     When row = 2:   2c(1-k) + ck = 2c - kc = (2-k)c <= cellcnt
    //     When row = 3:   3c(1-k) + ck = 3c - 2ck = (3-2k)c
    const k = 0.8;
    const r = val.children.length;
    const c = cols.size - 1;  // ignore the first column, as the key column is always there
    cellCnt -= r;
    // Limited number of cols due to performance reason
    return cols.size < 100 && cellCnt >= c * (r - (r - 1) * k);
  }

  nodeClicked(data: TDNode) { this.tstate.select(data); }

  queryData() {
    DataFilter.filter(this.tableOpt);
    this.tableOpt.xprops.filteredData = this.tableOpt.filteredData;
  }

  copy(asCSV = false) {
    // console.log(this.tableOpt.filteredData);
    // console.log('coloumns:');
    // console.log(this.tableOpt.columns, null, ' ');
    const data = this.tableOpt.filteredData;
    const exportData = this.tableOpt.columns[0].field === '@key' ? 
        TableUtil.rowsToObject(data, this.tableOpt) : data.map(r => TableUtil.rowToObject(r, this.tableOpt));

    // Array to object if there's "@key"
    this.copyBuffer = asCSV ? TableUtil.toCSV(exportData) : TD.stringify(exportData);
    console.log(`this.copyBuffer=${this.copyBuffer}`);
    this.$nextTick(() => {
      const textView = this.$refs.textViewCopyBuffer as HTMLTextAreaElement;
      textView.select();
      textView.setSelectionRange(0, 999999999);
      // document.execCommand('selectAll');
      const res = document.execCommand('copy');
      this.$bvToast.toast('Data is copied successfully', { autoHideDelay: 2000, appendToast: true, toaster: 'b-toaster-bottom-right' });
    });
  }


  @Watch('query', {deep: true})
  watchQuery() {
    console.log('query changed: ' + JSON.stringify(this.query));
    this.queryData(); 
  }

  @Watch('isColumnExpanded')
  watchisColumnExpanded() {
    if (this.isColumnExpanded !== this.isColumnExpandedBuild)
      this.rebuildTable(this.selected!);
  }

  @Watch('tableData', {immediate: true})
  watchTableData() {
    this.tstate = this.tableData && this.tableData instanceof TreeState ? this.tableData : new TreeState(this.tableData, new JSONParserPlugin());
  }

  @Watch('tstate.selected', {immediate: true})
  watchSelected(node: TDNode, oldNode: TDNode) {
    console.log(`tstate.selected: ${oldNode?.pathAsString} -> ${node?.pathAsString}`)
    if (oldNode && oldNode.doc === node.doc)
      this.tstate.saveTableState(oldNode, new TableNodeState(_.cloneDeep(
        this.tableOpt.query), this.expandState.expandLevel, this.tableOpt.columns, this.isColumnExpanded));

    const cachedState = this.tstate.getTableState(node);
    if (cachedState != null) {
      this.isColumnExpanded = cachedState.isColumnExpanded;
    } else {
      this.isColumnExpanded = this.defaultExpand(node);
      this.tableOpt.query.extendedFields = '';
      this.tableOpt.query.jsQuery = '$';
    }

    // this.tableOpt.query.offset = 0;
    // if (this.defTableOpt)
    //   this.defTableOpt.query = { limit: 100, offset: 0 };
    this.expandState = new ExpandState(cachedState ? cachedState.expandedLevel : 0, 0, this.expandState.showChildrenSummary);
    this.rebuildTable(node, cachedState);
  }

  @Watch('options', {immediate: true})
  optionsUpdated() { this.rebuildTable(this.selected!); }

  get selected(): TDNode | null {
    return this.tstate ? this.tstate.selected : null;
  }

  get applyCustomOpts() {
    return this.tstate.isInitialNodeSelected() && this.isColumnExpanded && this.options;
  }

  get query() { return this.tableOpt.query; }

  get hasTableTitleSlot() { return !!this.$slots.tableTitle; }

  onKeyPress(e: KeyboardEvent) {
    (this.$refs.expandControl as ExpandControl)?.onKeyPress(e);
  }

  onkeypressStopPropagation(e: KeyboardEvent) {
    // block keypress event from propagating to parent (trigger the full screen mode etc
    // console.log(`onkeypressStopPropagation: ${e.key}`);
    e.stopPropagation();
  }
}
